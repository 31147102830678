import React from 'react';
import './App.css';
import me from './me.jpg';

import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const pageStyle = {
  backgroundColor: '#D3B69C',
  height: '100vh',
  color: '#000000',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
}

function App() {
  
  function Navbar() {
    return (
      <div>
        <Row>
          <Col>
            <p style={{
                position: 'fixed',
                bottom: '25%',
                left: '25%',
                fontSize: '24px',
                fontFamily: 'eckmannpsych-medium, sans-serif',
                marginLeft: '1%',
                marginBottom: '2%',
                textAlign: 'left',
              }}>
              <div
                style={{
                  position: 'fixed',

                  left: '0%',
                  right: '2%',
                  top: '2%',
                  fontSize: '14px',
                  fontFamily: ' nitti-typewriter-normal, sans-serif',
                  fontWeight: '300',
                  //textAlign: 'left',
                }}
              >
                <Row>
                  <Col>
                  <div
                    style={{
                      fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
                      fontWeight: 400,
                      display: 'inline-block',
                      textAlign: 'left',
                      marginTop: '3%',
                      marginLeft: '8%',
                      fontSize: '24px',
                      fontStyle: "bold"
                      
                    }}
                  >
                    Samay Nathani
                  </div>

                  </Col>
                  <Col>
                    <div style={{
                      fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
                      fontWeight: 500,
                      display: 'inline-block',
                      textAlign: 'right',
                      marginTop: '3%',
                      fontSize: '14px',
                      position: 'fixed',
                      right: '8%',
                    }}>
                    <a className='hover-underline-animation' href='https://drive.google.com/file/d/1nHYs3UsRPXnhe0dueS1PjW9cWm1qZWHZ/view?usp=sharing' target={'blank'}>Resume</a> &nbsp; &nbsp;
                    <a className='hover-underline-animation' href="https://linkedin.com/in/samaynathani" target={'blank'}>LinkedIn</a> &nbsp; &nbsp;
                    <a href='mailto:samay.n@columbia.edu' className='hover-underline-animation' target={'blank'} style={{
                  fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif, sans-serif',
                  fontStyle: 'italic',
                }}>samay.n@columbia.edu</a>

                    </div>
                  </Col>
                </Row>
              </div>
            </p>
          </Col>
          <Col>
          </Col>
        </Row>
        </div>
    );
  }

  return (

    <div className="App" style={pageStyle}>
      <div>
        <Navbar/>

      </div>
      <div style={{

      }}>
        <Container style={{

        }}>
          <Row>
            <Col>
              <img src={me} alt="A photograph of of myself, taken  by my friend Bailey at Molasses Books in Brooklyn"  height={"50%"} style={{position: "fixed", top: "30%", left: "10%"}} />
            </Col>
            <Col>
              <div style={{
                position: "fixed",
                right: "0%",
                alignItems: "left",
                top: "30%",
                height: "100px",
                width: "35%",
                marginRight: "12%",
              }}>
          
                <p hidden={false} style={{lineHeight: "150%", textAlign: "left", fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif', fontWeight: 400}}>
                <br></br>
                <br></br>
                <br></br>
                <br></br>  
                <br></br>
                <br></br>       
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                </p>
              </div>
              
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default App;
